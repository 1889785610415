import React from "react"
import Layout from "../components/layout"
import Image from "../components/image"

export default () => (
  <Layout title="photography">
    <div className="">
      <Image />
    </div>
  </Layout>
)







